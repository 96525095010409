

































































































































































































































































































import Vue from "vue";
import NavSideBar from "@/components/candidate/NavSideBar.vue";
import { ViewJobDetails } from "@/interfaces/shared/view_job_details/view_job_details";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import JobDescriptionCard from "@/components/shared/view_job_details/JobDescriptionCard.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import CandidateEmployeeCard from "@/components/recruiter/CandidateEmployeeCard.vue";
import CriticalWorkFunctions from "@/components/shared/view_job_details/CriticalWorkFunctions.vue";
import JobCard from "@/components/candidate/candidate_jobs/JobCard.vue";
import { format_overall_score } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  COMMON_LOADING,
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID,
  VIEW_JOB_STATE
} from "@/store/modules/common/constants";
import router from "@/router";
import { GET_USER_DETAILS, USER_STATE } from "@/store/modules/auth/constants";
import JobTitleBlock from "@/components/shared/view_job_details/JobTitleBlock.vue";
import {
  ACTIVATE_PUBLISHED_JOB,
  FETCH_MATCHING_CANDIDATES,
  POST_JOB,
  PUSH_TOP_JOB_WITH_CANDIDATES,
  RECOMMEND_JOB_PROFILES,
  RECRUITER_LOADING,
  UPDATE_TOP_MATCHING_CANDIDATES
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import {
  ViewJobState,
  SkillList,
  JobData
} from "@/store/modules/common/interfaces";
import OtherSpecifications from "@/components/shared/view_job_details/OtherSpecifications.vue";
import EditJobDetails from "@/components/recruiter/jobs/EditJobDetails.vue";
import { JobsWithCandidates } from "@/store/modules/recruiter/interfaces";
import { Jobs } from "@/interfaces/data_objects/jobs";
import RootLoader from "@/components/shared/RootLoader.vue";
// import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { UserState } from "@/store/modules/auth/interfaces";
// import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
// import { IoTThingsGraph } from "aws-sdk";

export default Vue.extend({
  name: "ViewJobDetails",
  components: {
    RootLoader,
    EditJobDetails,
    OtherSpecifications,
    CandidateEmployeeCard,
    DataNotFound,
    JobTitleBlock,
    JobCard,
    CriticalWorkFunctions,
    SkillsWithGroups,
    JobDescriptionCard,
    ExtraDetails,
    NavSideBar
  },
  data(): ViewJobDetails {
    return {
      recommend_job_index: -1,
      previous_job: null,
      loading: true,
      edit_job_details: false,
      job: null, // For holding job data
      edit_icon: require("@/assets/icons/linear/edit.svg"),
      industry_icon: require("@/assets/icons/bold/devices.svg"),
      track_icon: require("@/assets/icons/bold/mobile.svg"),
      experience_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      view_on_linkedin_allowed_states: [
        ViewJobState.DEFAULT,
        ViewJobState.LINKEDIN_JOB_POST
      ],
      matching_candidates_allowed_states: [ViewJobState.JOB_LISTING],
      recommended_job_profile_allowed_states: [
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST
      ],
      critical_work_functions_allowed_states: [
        // ViewJobState.JOB_LISTING,
        // ViewJobState.STANDARDISED,
        // ViewJobState.STANDARDISED_JOB_POST,
        // ViewJobState.LINKEDIN_JOB_POST,
      ],
      other_specifications_allowed_states: [
        // ViewJobState.STANDARDISED,
        // ViewJobState.STANDARDISED_JOB_POST
      ],
      post_job_button_allowed_states: [
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      industries_allowed_states: [
        ViewJobState.DEFAULT,
        // ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      experience_allowed_states: [
        ViewJobState.DEFAULT,
        // ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      track_allowed_states: [
        ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      previous_view_job_state: ViewJobState.DEFAULT
    };
  },
  async created() {
    const job_id = parseInt(this.$route.params.id);
    if (!isNaN(job_id)) await this.init_data(job_id);
  },
  // Validating route param type is int on router enter
  beforeRouteEnter(to, from, next) {
    const param_id = parseInt(to.params.id);
    if (isNaN(param_id)) next(false);
    else next();
  },
  // Validating route param type is int on router param update
  beforeRouteUpdate(to, from, next) {
    const param_id = parseInt(to.params.id);
    if (isNaN(param_id)) next(false);
    else {
      this.set_view_job_state(ViewJobState.DEFAULT);
      this.init_data(param_id);
      next();
    }
  },
  computed: {
    ViewJobState() {
      return ViewJobState;
    },
    UserState() {
      return UserState;
    },
    ...mapGetters("recruiter", {
      recruiter_loading: RECRUITER_LOADING,
      get_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS,
      user_state: USER_STATE
    }),
    ...mapGetters("common", {
      common_loading: COMMON_LOADING,
      view_job_state: VIEW_JOB_STATE
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES,
      post_job: POST_JOB,
      activate_published_job: ACTIVATE_PUBLISHED_JOB
      // zappyhire_post_job: ZAPPYHIRE_JOB_POST
    }),
    ...mapMutations("recruiter", {
      push_top_job_with_candidates: PUSH_TOP_JOB_WITH_CANDIDATES,
      update_top_matching_candidates: UPDATE_TOP_MATCHING_CANDIDATES,
      set_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    ...mapActions("common", {
      get_job_by_id: GET_JOB_BY_ID,
      fetch_skill_group: FETCH_SKILLS_GROUP
    }),
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    format_overall_score,

    /**
     * Function to initialize job data
     * @param job_id
     */
    async init_data(job_id: number) {
      this.loading = true;
      // Get job from DB
      const response = await this.get_job_by_id(job_id);
      if (!response) {
        await router.push({
          name: "recruiter-error"
        });
        this.loading = false;
      } else {
        // Checking url starts with https => if not append https in start of url
        if (response.url && !response.url.startsWith("http")) {
          response.url = "https://" + response.url;
        }
        // Parsing skills
        if (response.skills_list) {
          let skills: string | string[] = [];
          skills = JSON.parse(response.skills_list);
          if (typeof skills === "string") skills = eval(skills);
          response.skills_list = await this.fetch_skill_group(skills);
        }
        this.job = {
          ...response,
          matching_candidates: [],
          candidates_loading: true
        };
        this.loading = false;
        // Fetching matching candidates for allowed states
        if (
          this.matching_candidates_allowed_states.includes(this.view_job_state)
        ) {
          // Fetching top 4 matching candidates
          const fetch_candidates = await this.fetch_matching_candidates({
            job_ids: [job_id],
            count: 20
          });
          // If candidates fetching failed => show error snackbar
          if (!fetch_candidates) {
            this.root_error(this.$t("errors.matching-candidates-failed"));
            if (this.job) {
              this.job.candidates_loading = false;
            }
          }
          // If candidates fetched successfully => set values
          else {
            if (this.job) {
              this.job.candidates_loading = false;
              this.job.matching_candidates = fetch_candidates;
            }
          }
        }
      }
    },
    /**
     * Function to post new job if user select standardised job
     */
    async post_new_job() {
      // If job exist
      if (this.job) {
        // If user edit job
        if (
          this.view_job_state === ViewJobState.EDIT_TEXT_JOB_POST ||
          this.view_job_state === ViewJobState.STANDARDISED_JOB_POST
        ) {
          const skills: SkillList[] = Array.isArray(this.job?.skills_list)
            ? this.job.skills_list
            : [];
          let skills_list = [];
          for (let skill of skills) {
            skills_list.push(skill.skill);
          }

          // Payload for post job
          const payload: JobData = {
            job_id: this.job.jid,
            job_title: this.job.job_title,
            job_description: this.job.job_description,
            job_industry: this.job.sector,
            job_type: this.job.employment_type ? this.job.employment_type : "",
            job_experience: this.job.experience,
            skills_list: JSON.stringify(skills_list),
            is_standardize: true
          };
          if (this.view_job_state === ViewJobState.EDIT_TEXT_JOB_POST)
            payload.edited = true;
          const form_data = new FormData();
          for (let item in payload) form_data.append(item, payload[item]);
          form_data.append("post_type", "text");
          // Post job
          const response = await this.post_job(form_data);
          // If job posted successfully
          if (response) {
            this.root_notification(
              this.$t("success-messages.job-post-success")
            );
            // Fetching job from  DB
            await this.fetch_job_and_update_top_jobs(
              response.standardized_data.jid
            );
          } else {
            this.root_error(this.$t("errors.job-post-failed"));
          }
        } else if (this.view_job_state === ViewJobState.LINKEDIN_JOB_POST) {
          await this.activate_posted_job(this.job.jid);
        } else if (this.view_job_state === ViewJobState.TEXT_JOB_POST) {
          await this.activate_posted_job(this.job.jid);
          return;
        } else if (this.view_job_state === ViewJobState.FILE_JOB_POST) {
          await this.activate_posted_job(this.job.jid);
          return;
        }
      }
    },
    edit_posted_job() {
      this.edit_job_details = true;
      // this.previous_job = this.job;
    },
    async update_job(job: JobsWithCandidates) {
      this.loading = true;
      this.job = job;
      this.set_view_job_state(ViewJobState.EDIT_TEXT_JOB_POST);
      this.edit_job_details = false;
      // Parsing skills
      if (job.skills_list) {
        // Fetching skills with group
        const skills_with_group = await this.fetch_skill_group(job.skills_list);
        if (skills_with_group) this.job.skills_list = skills_with_group;
        else {
          this.root_error(this.$t("errors.internal"));
          await router.push("/recruiter/jobs/post-job");
        }
      }
      this.loading = false;
    },
    /**
     * Function to activate LinkedIn posted job
     */
    async activate_posted_job(job_id: number) {
      // Activating published job
      const response = await this.activate_published_job(job_id);
      // If job posted successfully
      if (response) {
        this.root_notification(this.$t("success-messages.job-post-success"));
        await this.fetch_job_and_update_top_jobs(job_id);
        // if (this.user_details.zappyhire_configuration?.user_id) {
        //   const skills: SkillList[] =
        //     this.job && Array.isArray(this.job.skills_list)
        //       ? this.job.skills_list
        //       : [];
        //   let final_skills = "";
        //   for (let skill of skills) {
        //     final_skills += skill.skill + ", ";
        //   }
        //   if (final_skills.endsWith(", "))
        //     final_skills = final_skills.slice(0, final_skills.length - 2);
        //   // Not Used
        //   // let experience = 1;
        //   // if (this.job?.experience) {
        //   //   experience = parseInt(this.job.experience.split("")[0]);
        //   // }
        //   const payload = {
        //     job_id: job_id,
        //     job_description: this.job?.job_description,
        //     title: this.job?.job_title,
        //     location_split: {
        //       kochi: 1,
        //       tvm: 1
        //     },
        //     skills: final_skills,
        //     completion_date: moment().format("YYYY-MM-DD"),
        //     academic_eligibility: "G",
        //     min_experience: 1,
        //     max_experience: 2,
        //     preferred_current_salary: "5-10"
        //   } as ZappyhirePostjobPayload;
        //   await this.zappyhire_post_job(payload);
        // }
      } else {
        this.root_error(this.$t("errors.job-post-failed"));
      }
    },
    /**
     * Function to fetch job from DB and updating top job data set
     */
    async fetch_job_and_update_top_jobs(job_id: number) {
      const job = await this.get_job_by_id(job_id);
      const obj = {
        ...job,
        matching_candidates: [],
        candidates_loading: true
      };
      // Update top_posted_jobs data set
      this.push_top_job_with_candidates(obj);
      await router.push("/recruiter/jobs"); // Navigate to job listing page
      this.set_recommend_job_profiles([]);
    },
    /**
     * Function to handle go back pressing
     */
    async go_back_pressed() {
      if (this.view_job_state === ViewJobState.STANDARDISED_JOB_POST) {
        this.job = this.previous_job;
        this.set_view_job_state(this.previous_view_job_state);
        this.previous_job = null;
        this.previous_view_job_state = null;
      } else if (
        this.view_job_state === ViewJobState.EDIT_TEXT_JOB_POST &&
        this.previous_view_job_state === this.view_job_state
      ) {
        this.job = this.previous_job;
        this.set_view_job_state(this.previous_view_job_state);
        this.previous_job = null;
        this.previous_view_job_state = null;
      } else if (
        this.view_job_state === ViewJobState.DEFAULT ||
        this.view_job_state === ViewJobState.LINKEDIN_JOB_POST ||
        this.view_job_state === ViewJobState.TEXT_JOB_POST ||
        this.view_job_state === ViewJobState.FILE_JOB_POST
      ) {
        this.set_view_job_state(ViewJobState.DEFAULT);
        this.$router.back();
      } else {
        if (window.confirm("Are you Sure ?")) {
          this.$router.back();
        }
      }
    },
    /**
     * Function to process if user press recommend job profile
     * @param job
     */
    async job_pressed(job: Jobs) {
      this.loading = true;
      this.previous_view_job_state = this.view_job_state;
      this.previous_job = this.job;
      this.set_view_job_state(ViewJobState.STANDARDISED_JOB_POST);

      this.recommend_job_index = this.get_recommend_job_profiles.indexOf(job);
      const requested_job = await this.get_job_by_id(job.jid);
      if (!requested_job) {
        this.root_error("Failed to get job details");
      } else {
        this.previous_job = this.job;
        // Parsing skills
        if (requested_job.skills_list) {
          let skills: string | string[] = [];
          skills = JSON.parse(requested_job.skills_list);
          if (typeof skills === "string") skills = eval(skills);
          requested_job.skills_list = await this.fetch_skill_group(skills);
        }
        this.job = requested_job;
      }
      this.loading = false;
      // this.previous_view_job_state = this.view_job_state;
      // if (this.view_job_state === ViewJobState.LINKEDIN_JOB_POST)
      //   this.set_view_job_state(ViewJobState.STANDARDISED_JOB_POST);
      // else if (this.view_job_state === ViewJobState.TEXT_JOB_POST)
      //   this.set_view_job_state(ViewJobState.STANDARDISED_JOB_POST);
      // else if (this.view_job_state === ViewJobState.EDIT_TEXT_JOB_POST)
      //   this.set_view_job_state(ViewJobState.STANDARDISED_JOB_POST);
    }
  },
  destroyed() {
    this.set_view_job_state(ViewJobState.DEFAULT);
  }
});
