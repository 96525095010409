const PostDataPayload = {
  first_name: "Req",
  last_name: "Logs",
  date_of_birth: "1995-12-09",
  gender: "male",
  email_address: "some@example.com",
  telephone_number: "+92 333 1231234",
  country_of_residence: "ARE",
  emirate_of_residence: "AL Ain",
  are_you_willing_to_relocate: "yes",
  live_in_uae: "yes",
  is_member_referred: "yes",
  professional_experience: {
    years_of_work_experience: "3",
    current_employment_status: "Senior Software Engineer",
    if_there_are_gaps_in_your_work_history: "no",
    resume: {
      s3Link:
        "s3://staging-hcms-textract/nep/user_1078/118091707996720888_Profile.pdf",
      location:
        "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/nep/user_1078/118091707996720888_Profile.pdf"
    },
    profile_photo: {
      s3Link:
        "s3://staging-hcms-textract/nep/user_1078/684831707996720886_CV1.pdf",
      location:
        "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/nep/user_1078/684831707996720886_CV1.pdf"
    }
  },
  jobs: [
    {
      job_title: "Senior Software Engineer",
      organization: "NEP",
      sector: "ICT",
      start_date: "2022-08-01",
      end_date: ""
    }
  ],
  education: [
    {
      degree_earned_and_year: "BSCS 2022",
      field_of_study: "Computer Science",
      issuing_school_or_university: "Khalifa University",
      country_of_school_university: "United Arab Emirates"
    }
  ],
  three_references: [
    {
      full_name: "John Doe",
      phone: "+1 123 4567890",
      email: "some@example.com",
      reference_letter: {}
    }
  ],
  have_you_ever_been_a_speaker_or_presenter_at_an_event:
    "Yes, I have spoken at several tech conferences.",
  events: [
    {
      title: "Tech Summit 2023",
      description:
        "Gave a presentation on AI and its impact on software development. Explored emerging trends such as machine learning algorithms for predictive analytics and discussed the future of automated testing."
    },
    {
      title: "Developer Conference 2022",
      description:
        "Led a workshop on microservices architecture. Demonstrated best practices for designing scalable and resilient systems using containerization and Kubernetes orchestration."
    },
    {
      title: "Future Tech Expo 2021",
      description:
        "Spoke on a panel about the future of cybersecurity. Discussed the evolution of threat landscapes and strategies for implementing proactive defense measures such as threat intelligence and behavior analytics."
    }
  ],
  solution_to_problem:
    "Uh, adapting my point of view? Well, I guess there was this time at work where I thought my way was the best way to solve a problem. But then, like, my colleagues were all into this digital stuff, and I was like, Whatever. But then I figured, fine, I'll look into it. So, I did some quick research, but honestly, I didn't learn much. I guess I learned that sometimes you gotta pretend to care about what others think, even if you don't really get it.",
  lesson_learned:
    "Yeah, so there was this time we tried to change stuff at work, and people didn't like it. I mean, what do you expect? Change is hard, right? The most important lesson I learned? I guess it's that people don't like change. But whatever, we pushed through anyway.",
  strategies_taken_for_performance:
    "Strategies for evaluating my own performance? Uh, I dunno. I guess I just kinda do my thing and hope it's good enough. I don't really reflect on stuff or ask for feedback. I mean, who has time for that, right?",
  complex_problem:
    "Oh yeah, there was this big problem at work, and I totally broke it down into smaller tasks for my team. I mean, it wasn't that hard. I just told them what to do, and they did it. I didn't really do much myself, though. But hey, we got it done, I guess.",
  colleagues_ideas:
    "How do I make sure colleagues' ideas are considered? Well, I usually just ignore them and do things my way. I mean, why bother with other people's ideas when mine are obviously the best? Plus, listening to others is just a waste of time, right?",
  why_are_you_interested_in_joining_the_national_experts_program:
    "I am passionate about contributing to the development of my country's tech sector and believe that the program offers a unique opportunity to collaborate with experts and drive innovation. As a Senior Software Engineer, I have acquired a breadth of experience in the field and am eager to share my knowledge while also learning from others.",
  which_sector_would_you_like_to_apply_for:
    "Information and Communication Technology",
  how_many_years_have_you_worked_in_your_sector: 6,
  describe_your_current_role_in_your_sector:
    "As a Senior Software Engineer at HCMS, I am responsible for leading the development of cutting-edge software solutions and mentoring junior team members. I am actively involved in all phases of the software development lifecycle, from requirements gathering to deployment, ensuring that our products meet high standards of quality and performance.",
  provide_3_specific_examples_of_trends_that_you_believe_will_change_your_sector_in_the_next_five_years_and_why:
    "1. The rise of AI-driven development tools will streamline software development processes, increasing efficiency and reducing time-to-market. As AI continues to advance, developers will leverage machine learning algorithms to automate repetitive tasks and optimize code performance.\\n2. Blockchain technology will revolutionize data security and transaction integrity, leading to widespread adoption in sectors like finance and healthcare. By providing a decentralized and immutable ledger, blockchain ensures the integrity of sensitive information and facilitates secure transactions without the need for intermediaries.\\n3. The Internet of Things (IoT) will create new opportunities for interconnected systems and smart devices, transforming industries such as manufacturing and logistics. With the proliferation of IoT devices, companies can collect and analyze real-time data to optimize operations, improve decision-making, and enhance customer experiences.",
  arabic_video_url: "https://example.com/arabic_video",
  english_video_url: "https://example.com/english_video"
};

export default PostDataPayload;
