import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import { ListingPayload } from "@/interfaces/listing_payload";
import { FavoriteJobs } from "@/interfaces/responses/favorite_jobs";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";
import { AssessmentQuestions } from "@/interfaces/responses/assessment_questions";
import { SkillGap } from "@/interfaces/responses/skill_gap";
import { IAppAssessment } from "@/interfaces";
import { InterestAreas } from "../my_next_move/interfaces";

export interface CandidateState {
  loading: boolean;
  jobs_loading: boolean;
  matching_jobs: MatchingJob[];
  upskill_courses_loading: boolean;
  job_id_for_upskill: number | null;
  toggle_job_fav_status_loading: boolean;
  upskill_courses: UpskillCourses.UpskillCoursesRoot | null;
  searched_matching_jobs: {
    jobs: MatchingJob[];
    title: string;
    active: boolean;
  };
  interviews: CandidateInterviews;
  tenant_info: TenantInfo;
  candidate_jobs: CandidateJobs;
}

export interface MatchingJob {
  candidate_id: number;
  experience: string | null;
  jid: number;
  job_description: string;
  job_title: string;
  matching_data: MatchingData;
  score: number;
  sector: string;
  skills_list: string;
  employment_type: string | null;
  is_favorite?: boolean;
}

export interface UpskillCoursesActionPayload {
  response_id: string;
  job_id: string;
  gap_list: string[];
}

export interface SearchedMatchingJobsPayload {
  jobs: MatchingJob[];
  title: string;
  active: boolean;
}

export interface SearchAnyJobPayload extends ListingPayload {
  job_title: string;
}

export interface CareerPathPayload {
  uid: string;
  n: number;
}

// Action to like/dislike jobs
export enum FavJobActions {
  LIKE = "like",
  DISLIKE = "dislike"
}

export interface ToggleJobFavStatusPayload {
  job_id: number;
  action: FavJobActions;
}

// Payload for updating fav jobs status in store
export interface UpdateFavJobsStatus {
  fav_jobs: FavoriteJobs[];
}

// Payload for updating fav job status in store
export interface UpdateFavJobStatus {
  job_id: number;
  searched_matching_jobs: boolean;
  action: boolean;
}

export interface CandidateInterviews {
  search_job_loading: boolean;
  listing: CandidateInterviewsListing[] | null;
  pagination: number;
}

export interface CandidateInterviewsListing {
  interview_id: number;
  job_title: string;
  job_id: number;
  status: Interview.Status;
  date: string;
  class: string;
  readable_status: string;
}

export interface TenantInfo {
  loading: boolean;
  error: boolean;
  details: PrincipleAssessments | null; // DB principle_assessment table record
  total_questions: number; // Total questions
  progress_value: number; // Progress value
  assessment_questions: AssessmentQuestions.Root; // Get Assessment questions response
  assessment_record: AssessmentRecord[]; // Final record
  question_index: number; // Index used for assessment record
}
export interface AssessmentRecord {
  question: string;
  answerNumber: number;
  questionNumber: number;
}
export interface RegisterTenantPayload {
  tp_name: string;
}

export interface SubmitAssessmentAnswersPayload {
  answers: AssessmentAnswersPayload[];
  question_index: number;
}

export interface AssessmentAnswersPayload {
  questionNumber: number;
  answerNumber: number;
}

export enum AssessmentErrorTypes {
  QUESTIONS,
  REPORT
}

export interface FetchMatchingJobResponse {
  results: MatchingJob[];
  total: number;
}

export interface GetMatchingJobsPayload {
  candidate_id: number;
  page: number;
  limit: number;
  job_title?: string;
}
export interface MatchingData {
  gap: SkillGap.Skills[];
  matched: SkillGap.Skills[];
}

export interface MatchingJobs {
  loading: boolean;
  results: MatchingJob[];
  total: number;
  page: number;
  limit: number;
  job_title?: string;
}

export interface TopMatchingJobs {
  loading: boolean;
  results: MatchingJob[];
  total: number;
}
export interface CandidateJobs {
  matching_jobs: MatchingJobs;
  top_matching_jobs: TopMatchingJobs;
}

export interface SubmitAssessmentAnswersApiPayload {
  assessment_id: number;
  question_id: number;
  answer_type: IAppAssessment.QuestionType;
  answer: string | JSON | number | null;
  done?: boolean;
  user_id?: number;
  id?: number;
}

export interface FetchAssessmentQuestionnariesPayload {
  assessment_id: number;
  page?: number;
  limit?: number;
  user_id?: number;
}

export interface FetchAssessmentAnswersApiPayload {
  assessment_id: number;
  user_id: number;
  question_ids?: number;
  question_id?: number;
  answer_type?: IAppAssessment.QuestionType;
  page: number;
  limit: number;
}

export interface CandidateUploadMedia {
  interview_id: number;
  media: File;
}

export interface QuestionnairePayload {
  assessment_id: number;
  id: number;
  is_question_enable: boolean;
  question_text: string;
  question_type: string;
  user_id: number;
}
export interface AnswerPayload {
  assessment_id: number;
  id: number;
  is_question_enable: boolean;
  answer: string;
  answer_type: string;
  user_id: number;
  question_id: number;
}

export interface ChatBotPayload {
  role: string;
  content: string;
  picture?: string;
}

export enum ChatBotRoles {
  BOT = "BOT",
  USER = "USER"
}

export interface BulkSubmitAssessmentAnsPayload {
  assessment_id: number;
  answers: SubmitAssessmentAnswersApiPayload[];
}

export interface OnetResultPayload {
  result?: string;
  codes?: number[];
}

export interface OnetInterestAreaPayload {
  area: InterestAreas;
  zone: number;
}
