import { SidebarLinks } from "@/interfaces/candidate/nav_side_bar";
import { AppRoutes } from "@/interfaces";

const SidebarContent: SidebarLinks[] = [
  {
    name: "nav-sidebar.dashboard",
    link: "/candidate/dashboard",
    icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
    module: ["candidate"],
    children: []
  },
  {
    name: "nav-sidebar.interviews",
    link: "/candidate/nep-test",
    icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
    module: ["candidate"],
    children: [
      {
        name: "nav-sidebar.interviews",
        link: "/candidate/nep-test",
        icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        module: ["candidate"],
        children: []
      },
      {
        name: "Interview Report",
        link: "/candidate/nep-report",
        icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        module: ["candidate"],
        children: []
      },
      {
        name: "AI Evaluation & Score",
        link: "/candidate/nep-result",
        icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        module: ["candidate"],
        children: []
      }
    ]
  },
  {
    name: "nav-sidebar.assessment",
    link: AppRoutes.Candidae.PERSONALITY_ASSESSMENT,
    icon: require("@/assets/icons/nav_sidebar/assessment.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
    module: ["candidate"],
    children: []
  },
  {
    name: "nav-sidebar.settings",
    link: "/settings/account",
    icon: require("@/assets/icons/nav_sidebar/settings.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
    module: ["candidate"],
    children: [
      {
        name: "nav-sidebar.account",
        link: "/settings/account",
        icon: require("@/assets/icons/nav_sidebar/settings.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
        module: ["candidate"],
        children: []
      }
    ]
  },
  {
    name: "nav-sidebar.dashboard",
    link: "/recruiter/dashboard",
    icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: []
  },
  {
    name: "nav-sidebar.interviews",
    link: "/recruiter/interviews",
    icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: []
  },
  {
    name: "nav-sidebar.assessment",
    link: "/recruiter/assessments",
    icon: require("@/assets/icons/nav_sidebar/assessment.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: []
  },
  {
    name: "nav-sidebar.settings",
    link: "/settings/account",
    icon: require("@/assets/icons/nav_sidebar/settings.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: [
      {
        name: "nav-sidebar.account",
        link: "/settings/account",
        module: ["recruiter", "recruiter_admin"],
        children: []
      }
    ]
  }
];

export default SidebarContent;
